<template>
  <router-view />
</template>
<script setup>
import { inject } from 'vue'
import { logEvent } from 'firebase/analytics'
const analytics = inject('$analytics')
function handlePageClose () {
  logEvent(analytics, 'flashb_close', {
    view_code: 'uri',
    reason: sessionStorage.getItem('pageStatus')
  })
}

// 监听页面隐藏事件（pagehide）
window.addEventListener('pagehide', function (event) {
  if (event.persisted) {
    // 在页面刷新或后退时不执行操作
    return
  }

  handlePageClose()
})

// 监听视窗离开事件（blur）
window.addEventListener('blur', function (event) {
  // 在部分移动端浏览器中，将视窗切换到后台也会触发该事件
  logEvent(analytics, 'flashb_close', {
    view_code: 'uri',
    reason: sessionStorage.getItem('pageStatus')
  })
});

</script>
<style lang="scss">
#app {
  user-select: none;
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  background: linear-gradient(180deg, #FFFFFF 0%, #FF7D3F 100%);
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
