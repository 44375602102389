import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { analytics } from './analytics.js'
import './assets/css/index.css'
const app = createApp(App)
app.use(router)
console.log(analytics)
app.provide('$analytics', analytics)
app.mount('#app')
