import { initializeApp } from "firebase/app"
import { getAnalytics } from "firebase/analytics"

const firebaseConfig = {
  apiKey: "AIzaSyDz3TV0aGYnxEqFSzQUqYlWR8abRKxCE3o",
  authDomain: "android01-eeed4.firebaseapp.com",
  projectId: "android01-eeed4",
  storageBucket: "android01-eeed4.appspot.com",
  messagingSenderId: "663048572285",
  appId: "1:663048572285:web:7c729fef0f06868c861014",
  measurementId: "G-VLPWKRG8FC"
}

const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)

export { analytics }  